import React from "react";
import { FaTiktok, FaInstagram, FaSnapchatGhost } from "react-icons/fa";

import { FooterOverlay } from "../../components";
import { images } from "../../constants";
import "./Footer.css";

const Footer = () => (
  <div className="app__footer section__padding" id="footer">
    <FooterOverlay />


    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">725 Garrett Lane, London, SW17 OPD</p>
        <p className="p__opensans">02071678883</p>
        <p className="p__opensans" style={{ textTransform: 'none' }}>info@soulfuldelights.co.uk</p>
        <img src={images.fhr} alt="food_hygiene_rating" className="fhr" />
      </div>

      <div className="app__footer-links_logo">
        <img src={images.sdlogo} alt="footer_logo" />
        <p className="p__opensans">
          &quot;Food for the body is not enough.
          <br />There must be food for the soul.&quot;
        </p>
        <img
          src={images.spoon}
          className="spoon__img"
          style={{ marginTop: 15 }}
        />
        <div className="app__footer-links_icons">
        <a href="https://www.instagram.com/soulfuldelightsldn">
            <FaInstagram />
          </a>
          <a href="https://www.tiktok.com/@soulfuldelightsldn">
            <FaTiktok />
          </a>
          <a href="https://www.snapchat.com/add/soulful-delight">
            <FaSnapchatGhost />
          </a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Monday:</p>
        <p className="p__opensans">Closed</p>
        <p className="p__opensans">Tuesday-Saturday:</p>
        <p className="p__opensans">3:00 PM - 11:00 PM</p>
        <p className="p__opensans">Sunday:</p>
        <p className="p__opensans">2:00 PM - 10:00 PM</p>
      </div>
    </div>

    <div className="footer__copyright">
    <p className="p__opensans">
      {new Date().getFullYear()} Soulful Delights. All Rights reserved.
      <br />
      Made with ❤️ by{" "}
      <a href="https://www.nextleap.uk" className="footer__link">
        NextLeap
      </a>
    </p>
  </div>
  </div>
);

export default Footer;
